body {
  margin: 0;
}

.uploadcare--widget__button_type_open {
  display: none;
}

/**
* NEW: we removed all of this. reason being, we now hide the launcher at all times other
* than when the user explicitly opens it or we send them a message. this results in
* there not being an intercom chat "bubble" at all within the app
*
*  Move the intercom widget up above the mobile navbar, on small devices
*  IMPORTANT: setting these settings in the Intercom Admin Panel does not work
*    on mobile screen sizes. You must do this here in CSS.
*  TODO: how can we get mobile width here, instead of hardcoding 991px?
*/
@media (max-width: 991px) {
  /* .intercom-lightweight-app-launcher {
    bottom: 85px !important;
  } */

  /* this is needed bc after the user opens the intercom widget, the intercom-lightweight-app-launcher
  class no longer exists  */
  /* .intercom-with-namespace-52k34s {
    bottom: 85px !important;
  }

  iframe[name='intercom-notifications-frame'] {
    bottom: 137px !important;
  } */
}

/** Ensure the PayPal iframe doesn't take up space on the page
* When navigating to the purchase private lessons page, paypal adds an iframe which causes our app
* to add some whitespace at the bottom. This css removes that. Only one of these is technically
* needed, but in case paypal changes the id or name in future, we have both as a backup. Note that
* the id^ is saying "if the id contains this text", which is needed because the full id includes
* random uuid that changes with every pageload
*/
iframe[id^="hosted-fields-tokenization"] {
  position: absolute;
  display: none;
}
iframe[name^="hosted-fields-tokenization"] {
  position: absolute;
  display: none;
}

/* mux video player custom css */
.pgGroupsched-howtovid {
  width: auto !important;
}
